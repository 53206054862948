import { HttpClient } from "../utils/httpClient";

const PATH = {
  udp: "/udp",
};

const Read = (params, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.udp}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const Delete = (id, start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.udp}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const Create = (data, start, callback, error, next) => {
  start();
  return HttpClient.post(`${PATH.udp}`, data)
    .then(callback)
    .catch(error)
    .finally(next);
};

export const udpRoutingService = {
  Read,
  Create,
  Delete,
};
