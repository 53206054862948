import { HttpClient } from "../utils/httpClient";
const PATH={
    dashboard:"/dashboard",
    location:'/dashboard/getAllLocations',
    manager:'/dashboard/getAllManagers',
    filterManger:'/dashboard/getManagersByLocation',
    filterData:'/dashboard/getFilteredDevices'
}

const GetDashData=(start,callback,error,next)=>{
    start();
    return HttpClient.get(`${PATH.dashboard}/`)
        .then(callback)
        .catch(error)
        .finally(next); 
}

const GetDashLocationData=(start,callback,error,next)=>{
    start();
    return HttpClient.get(`${PATH.location}/`)
    .then(callback)
    .catch(error)
    .finally(next);
}

const GetDashManagerData=(start,callback,error,next)=>{
    start();
    return HttpClient.get(`${PATH.manager}/`)
    .then(callback)
    .catch(error)
    .finally(next);
}

const GetFilterManagerData=(start,id,callback,error,next)=>{
    start();
    return HttpClient.get(`${PATH.filterManger}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
}

const GetFilteredData=(start,id,id2,callback,error,next)=>{
    start();
    return HttpClient.get(`${PATH.filterData}/${id}/${id2}`)
        .then(callback)
        .catch(error)
        .finally(next);

}

export const DashboardServices={
    GetDashData,
    GetDashLocationData,
    GetDashManagerData,
    GetFilterManagerData,
    GetFilteredData
}