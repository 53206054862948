import React, { Fragment } from "react";
import { Grid, Switch } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Input } from "../../components/Input/Input";
import Button from "../../components/CustomButtons/Button";
import { toast } from "react-toastify";
import { udpRoutingService } from "../../services/UdpRoutingService";
import { UdpRuleValidation } from "../../validators/UdpDeviceValidator";
import { SwitchComponent } from "../../components/SwitchComponent/SwitchComponent";

const AddUdpRouting = ({
  classes,
  startLoader,
  stopLoader,
  setOpen,
  setRefresh,
}) => {
  const handleSubmit = (values) => {
    const temp = JSON.parse(JSON.stringify(values));
    temp.certificate = { certificate: values.certificate[0].split("\n") };
    temp.prePostEnable = values.prePostEnable ? "1" : "0";
    temp.connectRetain = values.connectRetain ? "1" : "0";
    temp.disconnectRetain = values.disconnectRetain ? "1" : "0";
    temp.tlsEnabled = values.tlsEnabled ? "1" : "0";
    udpRoutingService.Create(
      temp,
      () => startLoader("createRule"),
      handleSuccess,
      handleError,
      () => stopLoader("createRule")
    );
  };

  const handleSuccess = ({ data }) => {
    toast.success("Rule Created!");
    setRefresh((prev) => prev + 1);
    setOpen(false);
  };

  const handleError = (err) => {
    toast.error(err?.response?.data?.message);
  };

  return (
    <Fragment>
      <Grid container direction="column" spacing={2}>
        <div className="info-container">
          <Formik
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
            validationSchema={UdpRuleValidation}
            initialValues={{
              deviceId: "",
              host: "",
              port: "",
              topic: "",
              prePostEnable: false,
              connectMessage: "",
              disconnectMessage: "",
              connectTopic: "",
              disconnectTopic: "",
              connectRetain: false,
              disconnectRetain: false,
              tlsEnabled: false,
              certificate: [""],
            }}
          >
            {({ values }) => (
              <Form className={classes.blockFrom}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Field
                      component={Input}
                      name="deviceId"
                      type="text"
                      fullWidth
                      label="Device ID"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Field
                      component={Input}
                      name="host"
                      type="text"
                      fullWidth
                      label="Host"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Field
                      component={Input}
                      name="port"
                      type="text"
                      fullWidth
                      label="Port"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Field
                      component={Input}
                      name="topic"
                      type="text"
                      fullWidth
                      label="Topic"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={SwitchComponent}
                      name="prePostEnable"
                      type="checkbox"
                      fullWidth
                      label="Pre-Post Enable"
                    />
                  </Grid>
                  {values.prePostEnable && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Field
                          component={Input}
                          name="connectMessage"
                          type="text"
                          fullWidth
                          label="Connect Message"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Field
                          component={Input}
                          name="disconnectMessage"
                          type="text"
                          fullWidth
                          label="Disconnect Message"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Field
                          component={Input}
                          name="connectTopic"
                          type="text"
                          fullWidth
                          label="Connect Topic"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Field
                          component={Input}
                          name="disconnectTopic"
                          type="text"
                          fullWidth
                          label="Disconnect Topic"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Field
                          component={SwitchComponent}
                          name="connectRetain"
                          type="checkbox"
                          fullWidth
                          label="Connect Retain"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Field
                          component={SwitchComponent}
                          name="disconnectRetain"
                          type="checkbox"
                          fullWidth
                          label="Disconnect Retain"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={SwitchComponent}
                      name="tlsEnabled"
                      type="checkbox"
                      fullWidth
                      label="TLS Enabled"
                    />
                  </Grid>
                  {values.tlsEnabled && (
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="certificate.0"
                        type="text"
                        fullWidth
                        label="Certificate"
                        multiline
                        rows={4}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mb={2}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
    </Fragment>
  );
};

export default AddUdpRouting;
