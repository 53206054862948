import * as yup from "yup";
import { USER_ROLES } from "../constants";

export const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const LoginValidation = yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(8).max(42).required(),
});

export const EmailValidation = yup.object({
    email: yup.string().email().required()
});

export const ResetPasswordValidation = yup.object({
    email: yup.string().email().required(),
    userOtp: yup.string().required(),
    password: yup.string().required().min(8).max(42),
    confirmpassword: yup
        .string()
        .label('Confirm password')
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
        }),
});

export const UserCreateValidation = yup.object({
    edit: yup.boolean(),
    name: yup.string().required().min(3).max(25),
    contact: yup
        .string()
        .required()
        .matches(phoneRegex, "invalid contact number"),
    email: yup.string().required().email().max(50),
    password: yup.string().when("edit", {
        is: true,
        then: yup.string().nullable().min(8).max(42),
        otherwise: yup.string().required().min(8).max(42),
    }),
    companyName: yup.string().required().min(3).max(25),
    userRole: yup.string().required().oneOf(USER_ROLES),
    smsLimit: yup.number().required().min(0).max(1000),
    locationId: yup.string().required(),
    alertContactStatus: yup.number().required().oneOf([0, 1]),
    managerId: yup.string().when("userRole", {
        is: "assistant",
        then: yup.string().required(),
        otherwise: yup.string(),
    }),
});


export const UpdateProfileValidation = yup.object({
    name: yup.string().required().min(3).max(25),
    contact: yup
        .string()
        .matches(phoneRegex, "Contact Number Invalid"),
    password: yup.string(),
    confirmPassword: yup
        .string()
        .label('Confirm password')
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
        }),
    companyName: yup.string().required().min(3).max(25),
    email: yup.string().required().email().max(50),
    userRole: yup.string()
});

export const UserSignup = yup.object({
    name: yup.string().required().min(3).max(25),
    contact: yup
        .string()
        .required()
        .matches(phoneRegex, "invalid contact number"),
    email: yup.string().required().email().max(50),
    password: yup.string().when("edit", {
        is: true,
        then: yup.string().nullable().min(8).max(42),
        otherwise: yup.string().required().min(8).max(42),
    }),
    companyName: yup.string().required(),
    locationId: yup.string().required(),
    mobileOtp: yup.number().required(),
    companyAddress: yup.string().required(),
    country: yup.string().required(),
});
export const OTPValidation = yup.object({
    name: yup.string().required().min(3).max(25),
    contact: yup
        .string()
        .required()
        .matches(phoneRegex, "invalid contact number"),
    email: yup.string().required().email().max(50),
    password: yup.string().when("edit", {
        is: true,
        then: yup.string().nullable().min(8).max(42),
        otherwise: yup.string().required().min(8).max(42),
    }),
    companyName: yup.string().required(),
    locationId: yup.string().required(),
    companyAddress: yup.string().required(),
    country: yup.string().required(),

});