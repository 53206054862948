import React, { Fragment, useState, useContext, useEffect } from 'react';
import { FormDialog } from '../../components/FormDialog/FormDialog';
import { LoaderContext } from '../../context/LoaderContext';
import { BRIEFS, START_LOADER, STOP_LOADER } from '../../constants';
import { Grid, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Button from '../../components/CustomButtons/Button';
import { AddUser } from './AddUser';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Table from '../../components/Table/Table';
import { UserService } from '../../services/UserService';
import { CustomToolTip } from '../../components/CustomToolTip/CustomToolTip';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { LocationService } from '../../services/LocationService';
import { AuthService } from '../../services/AuthService';

const useStyles = makeStyles({
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  button: {
    float: 'right',
  },
  pagination: {
    marginLeft: 'auto',
  },
});

const LIMIT = 10;

export const UserPage = () => {
  const userRole = AuthService.getUserRole();
  const user = AuthService.getCurrentUser();
  const hasAccess = ['admin'].includes(userRole);
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [managers, setManagers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [userToEdit, setUserToEdit] = useState(null);
  const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const editUser = (id) => {
    setUserToEdit(id);
    setShowModal(true);
  };
  const users = useUsers(startLoader, stopLoader, refresh, pagination.curPage, setPagination, editUser, setRefresh, hasAccess);
  const tableHead = hasAccess
    ? ['SNo', 'Name', 'Contact', 'Email', 'CompanyName', 'Role', 'Options']
    : ['SNo', 'Name', 'Contact', 'Email', 'Options'];

  useEffect(() => {
    LocationService.ReadAll(
      () => startLoader('getLocations'),
      locationFetchSuccess,
      locationFetchError,
      () => stopLoader('getLocations')
    );
  }, []);
  const locationFetchSuccess = (res) => {
    const data = res.data.data;
    setLocations(data.locations);
  };
  const locationFetchError = (err) => {
    console.log(err);
  };
  useEffect(() => {
    UserService.GetManagers(
      () => startLoader('getManagers'),
      managerFetchSuccess,
      managerFetchError,
      () => stopLoader('getManagers')
    );
  }, [refresh, showModal]);
  const managerFetchSuccess = (res) => {
    const data = res.data.data;
    setManagers(data.managers);
  };
  const managerFetchError = (err) => {
    console.log(err);
  };

  const afterInsertSuccess = () => {
    setShowModal(false);
    setUserToEdit(null);
    setRefresh((prev) => prev + 1);
  };
  const onClose = () => {
    setShowModal(false);
    setUserToEdit(null);
  };
  return (
    <Fragment>
      <FormDialog
        show={showModal}
        onClose={onClose}
        title={`Add/Edit User`}
        content={
          <AddUser
            edit={userToEdit}
            locations={locations}
            managers={managers}
            startLoader={startLoader}
            stopLoader={stopLoader}
            onClose={afterInsertSuccess}
            hasAccess={hasAccess}
            userRole={userRole}
            user={user}
          />
        }
      />
      <Grid container direction='column' spacing={2}>
        <div className='info-container'>
          <div>
            <div className='info-heading'>{BRIEFS?.manageUsers?.heading}</div>
            <ul className='info-list'>
              {Object.values(BRIEFS?.manageUsers?.description || {}).map((desc) => (
                <li>{desc}</li>
              ))}
            </ul>
          </div>
          <div>
            <Grid item>
              <Button onClick={() => setShowModal(true)} className={classes.button} variant='contained' color='primary' mb={2}>
                Add User
              </Button>
            </Grid>
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>Users</h4>
            </CardHeader>
            <CardBody>
              <Table tableHeaderColor='primary' tableHead={tableHead} tableData={users} />
            </CardBody>
          </Card>
        </Grid>
        {pagination.totalPages > 1 ? (
          <Grid item xs={12} className={classes.pagination + ' paginate'}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.curPage}
              variant='outlined'
              color='primary'
              onChange={(e, value) =>
                setPagination((prev) => ({
                  ...prev,
                  curPage: value,
                }))
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

const useUsers = (startLoader, stopLoader, refresh, curPage, setPagination, editUser, setRefresh, hasAccess) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    UserService.Read(
      LIMIT,
      (curPage - 1) * LIMIT,
      () => startLoader('userFetch'),
      handleUserFetchSuccess,
      handleUserFetchError,
      () => stopLoader('userFetch')
    );
  }, [curPage, refresh]);
  const handleUserFetchSuccess = (res) => {
    console.log(res);
    const data = res.data.data;
    const pagination = data.pagination;
    setPagination((prev) => ({
      ...prev,
      totalPages: Math.ceil(pagination.totalCount / LIMIT),
    }));
    const processedData = hasAccess
      ? data.users.map((item) => [item.id, item.name, item.contact, item.email, item.companyName, item.userRole, getOptions(item)])
      : data.users.map((item) => [item.id, item.name, item.contact, item.email, getOptions(item)]);
    setUsers(processedData);
  };
  const handleUserFetchError = (err) => {
    console.log(err);
  };

  const handleUserDelete = (id, role) => {
    UserService.Delete(
      id,
      role,
      () => startLoader('userDelete'),
      () => setRefresh((prev) => prev + 1),
      () => setRefresh((prev) => prev + 1),
      () => stopLoader('userDelete')
    );
  };

  const getOptions = (user) => {
    return (
      <>
        <CustomToolTip title={'Edit User'} onClick={() => editUser(user.id)} Icon={EditIcon} />
        <CustomToolTip title={'Delete User'} onClick={() => handleUserDelete(user.id, user.userRole)} Icon={DeleteIcon} />
      </>
    );
  };
  return users.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x.slice(1)]);
};
