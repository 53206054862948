import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
} from '../../material-dashboard-react';

const sidebarStyle = (theme) => ({
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1',
    ...boxShadow,
    width: `${drawerWidth}vw`,
    [theme.breakpoints.up('md')]: {
      width: `${drawerWidth}vw`,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: `${drawerWidth}vw`,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}vw, 0, 0)`,
      ...transition,
    },
  },
  drawerPaperRTL: {
    [theme.breakpoints.up('md')]: {
      left: 'auto !important',
      right: '0 !important',
    },
    [theme.breakpoints.down('sm')]: {
      left: '0  !important',
      right: 'auto !important',
    },
  },
  logo: {
    position: 'relative',
    padding: '1.10vw 1.10vw',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '1.10vw',
      width: 'calc(100% - 2.20vw)',
      backgroundColor: 'rgba(' + hexToRgb(grayColor[6]) + ', 0.3)',
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '1.32vw',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '2.20vw',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor,
    },
  },
  logoLinkRTL: {
    textAlign: 'right',
  },
  logoImage: {
    width: '2.20vw',
    display: 'inline-block',
    maxHeight: '2.20vw',
    marginLeft: '0.73vw',
    marginRight: '1.102vw',
  },
  img: {
    width: '2.57vw',
    top: '1.61vw',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: blackColor,
      opacity: '.8',
    },
  },
  list: {
    marginTop: '1.47vw',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: whiteColor,
    },
  },
  itemLink: {
    width: 'auto',
    height: '2.94vw',
    margin: '0vw 1.02vw 0',
    display: 'block',
    padding: '0.36vw 1vw',
    transition: 'all 300ms linear',
    borderRadius: '3px',
    position: 'relative',
    backgroundColor: 'transparent',
    ...defaultFont,
  },
  itemSubLink: {
    width: 'auto',
    height: '2.20vw',
    transition: 'all 300ms linear',
    margin: '0.3vw 1.102vw 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '0.588vw 0.882vw',
    backgroundColor: 'transparent',
    ...defaultFont,
  },
  itemIcon: {
    width: '1.76vw',
    height: '2.204vw',
    fontSize: '1.76vw',
    lineHeight: '2.204vw',
    float: 'left',
    marginRight: '1.102vw',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
  },
  itemSubIcon: {
    width: '1.47vw',
    // height: "26px",
    fontSize: '1.102vw',
    lineHeight: '0.5em',
    float: 'left',
    marginRight: '1.102vw',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
  },
  itemIconRTL: {
    marginRight: '3px',
    marginLeft: '1.102vw',
    float: 'right',
  },
  itemText: {
    ...defaultFont,
    margin: '0',
    lineHeight: '2.20vw',
    fontSize: '1.02vw',
    color: whiteColor,
  },
  itemSubText: {
    ...defaultFont,
    margin: '0',
    // marginTop:"-5px",
    lineHeight: '1em',
    fontSize: '0.88vw',
    color: whiteColor,
  },
  itemTextRTL: {
    textAlign: 'right',
  },
  whiteFont: {
    color: whiteColor,
  },
  purple: {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
    '&:hover,&:focus': {
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },
  blue: {
    backgroundColor: infoColor[0],
    boxShadow:
      '0 12px 20px -0.73vw rgba(' +
      hexToRgb(infoColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 0.588vw 0.882vw rgba(' +
      hexToRgb(infoColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: infoColor[0],
      boxShadow:
        '0 12px 20px -0.73vw rgba(' +
        hexToRgb(infoColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 0.588vw 0.882vw rgba(' +
        hexToRgb(infoColor[0]) +
        ',.2)',
    },
  },
  green: {
    backgroundColor: successColor[0],
    boxShadow:
      '0 12px 20px -0.73vw rgba(' +
      hexToRgb(successColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 0.588vw 0.882vw rgba(' +
      hexToRgb(successColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: successColor[0],
      boxShadow:
        '0 12px 20px -0.73vw rgba(' +
        hexToRgb(successColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 0.588vw 0.882vw rgba(' +
        hexToRgb(successColor[0]) +
        ',.2)',
    },
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow:
      '0 12px 20px -0.73vw rgba(' +
      hexToRgb(warningColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 0.588vw 0.882vw rgba(' +
      hexToRgb(warningColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: warningColor[0],
      boxShadow:
        '0 12px 20px -0.73vw rgba(' +
        hexToRgb(warningColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 0.588vw 0.882vw rgba(' +
        hexToRgb(warningColor[0]) +
        ',.2)',
    },
  },
  red: {
    backgroundColor: dangerColor[0],
    boxShadow:
      '0 12px 20px -0.73vw rgba(' +
      hexToRgb(dangerColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 0.588vw 0.882vw rgba(' +
      hexToRgb(dangerColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: dangerColor[0],
      boxShadow:
        '0 12px 20px -0.73vw rgba(' +
        hexToRgb(dangerColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 0.588vw 0.882vw rgba(' +
        hexToRgb(dangerColor[0]) +
        ',.2)',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '19vw',
    zIndex: '4',
    overflowScrolling: 'touch',
  },
  activePro: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: '13px',
    },
  },
});

export default sidebarStyle;
