import { HttpClient } from "../utils/httpClient";

const PATH = {
    create: "/alert",
    read: "/alert",
    getDeviceSensor: "/getDeviceSensor",
    readAlertLog: "/alertLog",
    ack: "/alertLog/acknowledge",
    delete: "/alert",
    export:"/alertLog/export"
   
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.create}`, payload)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Read = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}?limit=${limit}&offset=${offset}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const GetDeviceSensor = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getDeviceSensor}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const ReadAlertLog = (limit, offset, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAlertLog, { params: { limit, offset } })
        .then(callback)
        .catch(error)
        .finally(next);
};

const AckAlert = (id, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.ack}/${id}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const ExportData = (callback, error, next) => {
    return HttpClient.get(`${PATH.export}`)
        .then(callback)
        .catch(error)
        .finally(next);
};

const Delete = (id, role, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`, {
        params: { userRole: role },
    })
        .then(callback)
        .catch(error)
        .finally(next);
};



export const AlertService = {
    Create,
    Read,
    ReadAlertLog,
    AckAlert,
    GetDeviceSensor,
    Delete,
    ExportData
};
