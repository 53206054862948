import React,{useContext} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import { UpdateProfileValidation } from "../../validators/userValidator";
import { START_LOADER, STOP_LOADER } from "../../constants";
import { LoaderContext } from "../../context/LoaderContext";
import { useState } from "react";
import { useEffect } from "react";
import { UserService } from "../../services/UserService";
import { AuthService } from "../../services/AuthService";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const userId=AuthService.getUserId();
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const [response, setResponse] = useState(null)
  const initialValues = useInitialValues(startLoader,stopLoader,userId)

  const updateUserSuccess = ({data}) =>{
      data.error ? setResponse("Profile update failed") : setResponse("Profile update success")
  }

  const handleUpdateError = err =>{
      setResponse("Profile update failed")
      console.log(err)
  }

  const handleSubmit = (values) =>{
      setResponse(null)
      delete values['confirmPassword']
      if(!values.password) delete values['password']
      console.log(values)
      UserService.Update(
          userId,
          values,
          ()=>startLoader('Update User'),
          updateUserSuccess,
          handleUpdateError,
          ()=>stopLoader('Update User')
      )
  }

  return (
    <div>
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={UpdateProfileValidation}
            enableReinitialize
        >
        {({ isSubmitting }) => (   
            <Form>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8} style={{margin:'auto'}}>
                    <Card className=" w-77 margin-auto">
                        <CardHeader color="primary">
                        <h4>Edit Profile</h4>
                        <p className={classes.cardCategoryWhite}>Complete your profile</p>
                        </CardHeader>
                        <CardBody style={{display:'grid',height:'200px'}}>
                        <GridContainer style={{margin:'16px -17px !important'}}>
                            <GridItem xs={12} sm={12} md={4}>
                                    <Field
                                            component={Input}
                                            name="name"
                                            type="text"
                                            fullWidth
                                            label="Name"
                                    />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                    <Field
                                            component={Input}
                                            name="contact"
                                            type="text"
                                            fullWidth
                                            label="Contact"
                                    />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                    <Field
                                            component={Input}
                                            name="companyName"
                                            type="text"
                                            fullWidth
                                            label="Company"
                                    />
                            </GridItem>
                        </GridContainer>
                        <GridContainer style={{margin:'16px -17px !important'}}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Field
                                    component={Input}
                                    name="email"
                                    type="text"
                                    fullWidth
                                    label="Email"
                                    />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Field
                                    component={Input}
                                    name="password"
                                    type="password"
                                    fullWidth
                                    label="Password"
                                    />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Field
                                    component={Input}
                                    name="confirmPassword"
                                    type="password"
                                    fullWidth
                                    label="Confirm Password"
                                    />
                            </GridItem>
                        </GridContainer>
                        <GridContainer style={{margin:'16px -17px !important'}}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Field
                                    component={Input}
                                    name="userRole"
                                    type="text"
                                    label="Role Assigned"
                                    disabled
                                    fullWidth
                                    />
                            </GridItem>
                        </GridContainer>
                        {response ? 
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <p 
                                        className={response==="Profile update success" 
                                                    ? " success-msg" 
                                                    : "error-msg"}
                                    >
                                    {response}</p>
                                </GridItem>
                            </GridContainer> : null}
                        </CardBody>
                        <CardFooter className="margin-auto">
                                <Button color="primary" type="submit">Update Profile</Button>
                        </CardFooter>
                    </Card>
                    </GridItem>
                </GridContainer>
            </Form>
        )}
      </Formik>
    </div>
  );
}


const useInitialValues = (startLoader, stopLoader,userId) => {
    const [initialValues, setInitialValues] = useState({
        name: "",
        contact: "",
        companyName: "",
        email: "",
        password: "",
        confirmPassword:"",
        userRole: "",
    });
    useEffect(() => {
            UserService.ReadById(
                userId,
                () => startLoader("getUser"),
                userFetchSuccess,
                userFetchError,
                () => stopLoader("getUser")
            );
    }, []);

    const userFetchSuccess = (res) => {
        const {name,contact,companyName,email,userRole} = res.data.data;
        let processedData={
            name,
            contact,
            companyName,
            email,
            userRole,
            password:"",
            confirmPassword:""
        }
        setInitialValues(processedData);
    };
    const userFetchError = (err) => {
        console.log(err);
    };

    return initialValues;
};