import React, { useContext, Fragment, useEffect, useState } from 'react';
import { Grid, makeStyles, Button as MuiButton } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { DropDown } from '../../components/DropDown/DropDown';
import Button from '../../components/CustomButtons/Button';
import { DashboardServices } from '../../services/DashBoardServices';
import { BuildingService } from '../../services/BuildingService';
import DashCard from '../../components/Card/DashCard';
import MapComponent from '../../components/Map/MapComponent';
import BuildingMapComponent from '../../components/Map/BuildingMapComponent';
import { AuthService } from '../../services/AuthService';
import { START_LOADER, STOP_LOADER } from '../../constants';
import { LoaderContext } from '../../context/LoaderContext';
import dashboardClass from "./dashboard.module.css"
const colorFilter = [
  { id: 2, name: 'All' },
  { id: 0, name: 'Green' },
  { id: 1, name: 'Red' },
];
const useStyles = makeStyles({
  buttonContainer: {
    maxWidth: '17.333333% !important',
  },
  MuiButton: {
    color: 'purple',
    border: '1px solid purple',
    fontSize: '0.7rem',
    padding: '0.3rem',

  },
  MuiButton2: {
    color: '#0064C1',
    border: '1px solid #0064C1',
    fontSize: '0.7rem',
    padding: '0.3rem',
    width: "100%"
  },
  MuiButtonSelected:{
    color: 'white',
    border: '1px solid white',
    fontSize: '0.7rem',
    padding: '0.3rem',
    width: "100%",
    backgroundColor:"#0064C1"
  },
  MuiButtonContainer: {
    margin: 'auto',
  },
});

export const DashboardPage = () => {
  const userRole = AuthService.getUserRole();
  const { dispatch } = useContext(LoaderContext);
   const hasAccess= ['admin'].includes(userRole)
  const [deviceData, setDeviceData] = useState([]);
  const [deviceCardData, setDeviceCardData] = useState([]);
  const initialValues = {
    locationName: '',
    customerName: '',
    filter: '',
  };


  const [locationData, setLocationData] = useState([]);
  const [mangerData, setManagerData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [selectedManager, setSelectedMangaer] = useState(0);
  const [selectedColor, setSelectedColor] = useState(2);
  const [allData, setAllData] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [map, setMap] = useState(false);
  const [buildingMap, setbuildingMap] = useState(false);
  const [deviceMap, setdeviceMap] = useState(false);
  const [location, setLocation] = useState({});

  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });

  useEffect(() => {
    DashboardServices.GetDashData(
      () => startLoader('dataLoading'),
      handleFetchSuccess,
      (err) => console.log(err),
      () => stopLoader('dataLoading')
    );

    DashboardServices.GetDashLocationData(
      () => startLoader('dataLocationLoading'),
      handleFetchLocationSuccess,
      () => console.log('error'),
      () => stopLoader('dataLocationLoading')
    );
    DashboardServices.GetDashManagerData(
      () => startLoader('dataManagerLoading'),
      handleFetchManagerSuccess,
      () => console.log('error'),
      () => stopLoader('dataManagerLoading')
    );


  }, []);

  const handleFetchSuccess = async (res) => {

    const data = await res.data.data;
    if (data.mapData.length) {
      setLocation({ lat: data.mapData[0].lat, lng: data.mapData[0].lng });
    } else {
      setLocation({ lat: 51.5074, lng: 0.1278 });
    }

    setDeviceData(data.mapData);
    setBuildings(data.buildingData)
    setAllData(data.deviceData);
    setDeviceCardData(data.deviceData)
  };
  const handleFetchLocationSuccess = async (res) => {
    const data = await res.data.data.locations;
    const tempData = {
      id: 0,
      locationName: 'All',
    };
    data.unshift(tempData);
    setLocationData(data);
  };

  const handleFetchManagerSuccess = async (res) => {
    const data = await res.data.data.managers;
    const tempData = {
      id: 0,
      name: 'All',
    };
    data.unshift(tempData);
    setManagerData(data);
  };

  const filterManger = (value) => {
    setSelectedLocation(value);
    DashboardServices.GetFilterManagerData(
      () => startLoader('dataLoading'),
      value,
      handleFetchManagerFilterSuccess,
      () => setManagerData([{ id: 0, name: 'All' }]),
      () => stopLoader('dataLoading')
    );
  };
  const handleFetchManagerFilterSuccess = async (res) => {
    const data = await res.data.data.managers;
    const tempData = {
      id: 0,
      name: 'All',
    };
    data.unshift(tempData);
    setManagerData(data);
  };
  const handleSubmit = () => {
    setSelectedColor(2);
    hasAccess&&  DashboardServices.GetFilteredData(
      () => startLoader('dataLoading'),
      selectedLocation,
      selectedManager,
      handleFetchSuccess1,
      (err) => console.log(err),
      () => stopLoader('dataLoading')
    );
  };
  const handleFetchSuccess1 = async (res) => {
    const data = await res.data.data;
    // if (data.deviceData.length) setLocation({ lat: data.deviceData[0].lat, lng: data.deviceData[0].lng });

    setDeviceCardData(data.deviceData);
    setBuildings(data.buildingData)
    setDeviceData(data.mapData);
    setAllData(data.deviceData);
  }
  const changeColor = (value) => {
    if (value === 1 || value === 0) {
      const filterData = allData.filter((item) => item.status === value);
      const filterMapData = allData.filter((item) => item.status === value && item.locationMode === "Gps");
      setDeviceCardData(filterData);
      setDeviceData(filterMapData);
    } else {

      setDeviceCardData(allData);
    }
    setSelectedColor(value);
  };
  return (
    <Fragment>
      <Grid container direction='column' spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TopBar
            setMap={setMap}
            setdeviceMap={setdeviceMap}
            deviceMap={deviceMap}
            map={map}
            buildingMap={buildingMap}
            setbuildingMap={setbuildingMap}
            initialValues={initialValues}
            locationData={locationData}
            mangerData={mangerData}
            selectedLocation={selectedLocation}
            selectedManager={selectedManager}
            setSelectedLocation={setSelectedLocation}
            setSelectedMangaer={setSelectedMangaer}
            filterManger={filterManger}
            handleSubmit={handleSubmit}
            selectedColor={selectedColor}
            changeColor={changeColor}
            userRole={userRole}
          />
        </Grid>
        <Grid item>
          {map && deviceMap ? (
            <div style={{ height: '80vh', width: '100%' }}>
              <MapComponent isMarkerShown locations={deviceData} defaultLocation={location} />
            </div>
          ) : map && buildingMap ? (<div style={{ height: '80vh', width: '100%' }}>
            <BuildingMapComponent startLoader={startLoader} stopLoader={stopLoader} isMarkerShown locations={buildings} defaultLocation={location} />
          </div>) : (
                <Grid container spacing={4}>
                  {deviceCardData !== undefined
                    ? deviceCardData.map((item) => (
                      <Grid item xs={4}>
                        <DashCard item={item} />
                      </Grid>
                    ))
                    : ''}
                </Grid>
              )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const TopBar = ({
  initialValues,
  locationData,
  mangerData,
  selectedLocation,
  selectedManager,
  setSelectedLocation,
  setSelectedMangaer,
  filterManger,
  handleSubmit,
  selectedColor,
  setSelectedColor,
  changeColor,
  setMap,
  map,
  userRole,
  buildingMap,
  setbuildingMap,
  setdeviceMap,
  deviceMap
}) => {
  const classes = useStyles();
  const filterRoles = ['admin'];
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, isSubmitting, values }) => (
        <Form>
          <Grid container direction="column" spacing={2}>
          <Grid container item xs={12} md={12} lg={12} xl={12} spacing={2}>
                {filterRoles.includes(userRole) && (
                  <>
                    <Grid item xs={3}>
                      <Field
                        component={DropDown}
                        name='locationName'
                        value={selectedLocation}
                        onChange={(value) => filterManger(value.target.value)}
                        type='select'
                        values={locationData.map((x) => ({
                          id: x.id,
                          name: x.locationName,
                        }))}
                        fullWidth
                        label='Location Name'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={DropDown}
                        value={selectedManager}
                        onChange={(data) => setSelectedMangaer(data.target.value)}
                        name='customerName'
                        type='select'
                        values={
                          mangerData &&
                          mangerData.map((x) => ({
                            id: x.id,
                            name: x.name,
                          }))
                        }
                        fullWidth
                        label='Customer Name'
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.buttonContainer}>
                      <Button type='submit' variant='contained' color='primary' mb={2}>
                        Submit
                  </Button>
                    </Grid>
                  </>
                )}
                <Grid item xs={2}>
                  <Field
                    component={DropDown}
                    name='filter'
                    type='select'
                    value={selectedColor}
                    onChange={(data) => changeColor(data.target.value)}
                    values={colorFilter.map((x) => ({
                      id: x.id,
                      name: x.name,
                    }))}
                    fullWidth
                    label='Filter'
                    disabled={buildingMap}
                  />
                </Grid>
                <Grid item xs={2} className={classes.MuiButtonContainer} style={{ marginRight: 'inherit' }}>
                  <MuiButton className={classes.MuiButton} type='submit' variant='outlined' color='primary' mb={2} onClick={() => {
                    setMap(!map)
                    setdeviceMap(!deviceMap)
                  }}>
                    {map ? 'Switch to card view' : 'Switch to map view'}
                  </MuiButton>
                </Grid>

              </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}container direction="row" spacing={2} >
        
            {map ? (<>  <Grid item xs={2} >
              <MuiButton className={deviceMap?classes.MuiButtonSelected:classes.MuiButton2} type='submit' variant='outlined'  mb={2} onClick={() => {
                setbuildingMap(false)
                setdeviceMap(true)
                
              }}>
                View Device
              </MuiButton>
            </Grid>
            </>) : null}
            {map ? (<>  <Grid item xs={2} >
              <MuiButton className={buildingMap?classes.MuiButtonSelected:classes.MuiButton2}  type='submit' variant='outlined'  mb={2} onClick={() => {
                setbuildingMap(true)
                setdeviceMap(false)
              }}>
                View Building
              </MuiButton>
            </Grid>
            </>) : null}
          </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
