import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { SensorAddValidation } from "../../validators/SensorValidator";
import { SensorService } from "../../services/SensorService";
import { DashboardServices } from '../../services/DashBoardServices'
import { useState } from "react";
import { DropDown } from "../../components/DropDown/DropDown";
import { AuthService } from "../../services/AuthService";
const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "center",
    },
});
export const AddSensor = ({ startLoader, stopLoader, onSuccess, onClose }) => {
    const userRole=AuthService.getUserRole();
    const userId=AuthService.getUserId();
    const hasAccess=userRole==="admin"
    const classes = useStyles();
    const [managers,setManagers]=useState([])
    const handleSubmit = (values) => {
        SensorService.Create(
            values,
            () => startLoader("sensorCreate"),
            sensorCreateSuccess,
            sensorCreateError,
            () => stopLoader("sensorCreate")
        );
    };
    
    const initialValues = {
        sensorName: "",
        userId: userRole==="manager" ? userId : ""
    };

    const sensorCreateSuccess = (res) => {
        const data = res.data.data;
        onSuccess(data);
        onClose();
    };
    const sensorCreateError = (err) => {
        console.log(err);
        onClose();
    };

    useEffect(()=>{
        DashboardServices.GetDashManagerData(
            ()=>startLoader('getManagers'),
            getManagersSuccess,
            (err)=>console.log(err),
            ()=>stopLoader('getManagers')
        )
    },[])

    const getManagersSuccess = res =>{
        const data = res.data.data.managers;
        setManagers(data)
        console.log(data)
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={SensorAddValidation}
            initialValues={initialValues}
        >
            <Form>
                <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                        <Field
                            component={Input}
                            name="sensorName"
                            type="text"
                            fullWidth
                            label="Sensor Name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                            <Field
                                    component={DropDown}
                                    name="userId"
                                    type="select"
                                    values={managers&&managers.map((x) => ({
                                        id: x.id,
                                        name: x.name,
                                    }))}
                                    fullWidth
                                    disabled={!hasAccess}
                                    label="Manager"
                                />
                    </Grid>
                    <Grid item xs={12} className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            mb={2}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};
