import { HttpClient } from "../utils/httpClient";

const PATH = {
  getData: "/udpLog",
  deleteAll: "/udpLog",
};

const Read = (params, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getData}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const DeleteAll = (start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.deleteAll}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

export const UdpLogService = {
  Read,
  DeleteAll,
};
