import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../components/Input/Input";
import Button from "../../components/CustomButtons/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { UserService } from "../../services/UserService";
import { UserCreateValidation } from "../../validators/userValidator";
import { DropDown } from "../../components/DropDown/DropDown";
import { USER_ROLES } from "../../constants";

const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "center",
    },
});
export const AddUser = ({
    locations,
    managers,
    startLoader,
    stopLoader,
    onSuccess,
    onClose,
    edit = null,
    hasAccess,
    userRole,
    user
}) => {
    const classes = useStyles();
    const ref = useRef(user);
    const [error,setError]=useState(null)
    useEffect(() => {
       UserService.ReadById(
           user.id,
           ()=>startLoader(),
           (res)=>ref.current=res.data.data,
           (err)=>console.log(err),
           ()=>stopLoader()
       )
    }, [])
    const initialValues = useInitialValues(startLoader, stopLoader, edit, locations,userRole,ref.current);
    const handleSubmit = (values) => {
        setError(null)
        const payload = values;
        delete payload["edit"];
        if (payload["userRole"] !== "assistant") delete payload["managerId"];
        console.log(payload)
        if (edit) {
            if (initialValues.managerId !== payload.managerId) {
                payload.managerChanged = true;
            }
            UserService.Update(
                edit,
                payload,
                () => startLoader("userUpdate"),
                userCreateSuccess,
                userCreateError,
                () => stopLoader("userUpdate")
            );
        } else {
            UserService.Create(
                payload,
                () => startLoader("userCreate"),
                userCreateSuccess,
                userCreateError,
                () => stopLoader("userCreate")
            );
        }
    };
    const userCreateSuccess = (res) => {
        const data = res.data.data;
        if(res.data.error){
            setError(res.data.message)
            return
        }
        onSuccess(data);
        onClose();
    };
    const userCreateError = (err) => {
        setError(null)
        console.log(err);
        onClose();
    };
    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={UserCreateValidation}
            initialValues={initialValues}
            enableReinitialize
        >
            {({ handleSubmit, isSubmitting, values }) => (
                <Form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="name"
                                type="text"
                                fullWidth
                                label="Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="contact"
                                type="text"
                                fullWidth
                                label="Contact"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="companyName"
                                type="text"
                                fullWidth
                                label="Company"
                                disabled={!hasAccess}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="email"
                                type="text"
                                fullWidth
                                label="Email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="password"
                                type="password"
                                fullWidth
                                label="Password"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={Input}
                                name="smsLimit"
                                type="text"
                                fullWidth
                                label="SMS Limit"
                                disabled={!hasAccess}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="alertContactStatus"
                                type="select"
                                values={[
                                    { id: 0, name: "Disabled" },
                                    { id: 1, name: "Enabled" },
                                ]}
                                fullWidth
                                label="Alert Contact"
                                disabled={!hasAccess}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="locationId"
                                type="select"
                                values={locations&&locations.map((x) => ({
                                    id: x.id,
                                    name: x.locationName,
                                }))}
                                fullWidth
                                label="Location"
                                disabled={!hasAccess}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="userRole"
                                type="select"
                                values={USER_ROLES.map((x) => ({
                                    id: x,
                                    name: x,
                                }))}
                                fullWidth
                                disabled={!hasAccess||Boolean(edit)}
                                label="Role"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Field
                                component={DropDown}
                                name="mqttAdmin"
                                type="select"
                                values={[{
                                    id: "Active",
                                    name: "Active",
                                },{
                                    id: "Inactive",
                                    name: "Inactive",
                                }]}
                                fullWidth
                                disabled={!hasAccess}
                                label="MQTT Admin"
                            />
                        </Grid>
                        {(userRole === "admin" && values.userRole === "assistant") ? (
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Field
                                    component={DropDown}
                                    name="managerId"
                                    type="select"
                                    values={managers&&managers.map((x) => ({
                                        id: x.id,
                                        name: x.name,
                                    }))}
                                    fullWidth
                                    label="Manager"
                                    disabled={!hasAccess}
                                />
                            </Grid>
                        ) : null}
                        {error ? 
                        <Grid>
                            <p className="error-msg" style={{marginLeft:'13px'}}>{error}</p>
                        </Grid> : null}
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                mb={2}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

const useInitialValues = (startLoader, stopLoader, edit, locations, userRole,user) => {
    const [initialValues, setInitialValues] = useState({
        name: "",
        contact: "",
        email: "",
        password: "",
        companyName: "",
        userRole: "",
        smsLimit: 0,
        locationId: "",
        alertContactStatus: 0,
        managerId: "",
        mqttAdmin: 'Inactive',
        edit: false,
    });
    useEffect(() => {
        if (edit) {
            UserService.ReadById(
                edit,
                () => startLoader("getUser"),
                userFetchSuccess,
                userFetchError,
                () => stopLoader("getUser")
            );
        }
        else if(userRole==="manager")
            setInitialValues({
                ...user,
                name: "",
                contact: "",
                email: "",
                password: "",
                smsLimit: 0,
                alertContactStatus: 0,
                userRole:"assistant",
                mqttAdmin: "Inactive",
                managerId: user.id,
            })
    }, [edit,user]);

    const userFetchSuccess = (res) => {
        const data = res.data.data;
        data.edit = true;
        console.log(data)
        setInitialValues(data);
    };
    const userFetchError = (err) => {
        console.log(err);
    };

    return initialValues;
};
