import React, { useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import { Formik, Form, Field } from "formik";
import { START_LOADER, STOP_LOADER, DAYS, BRIEFS } from "../../constants";
import { LoaderContext } from "../../context/LoaderContext";
import { useState } from "react";
import { useEffect } from "react";
import { AutoReportService } from "../../services/AutoReportService";
import {
  DropDown,
  MultiSelectDropDown,
} from "../../components/DropDown/DropDown";
import { DashboardServices } from "../../services/DashBoardServices";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { DownloadAsExcelMultipleSheet } from "../../utils/downloadAsExcel";

let HOURS = [],
  DATES = [],
  i;
for (i = 0; i < 30; i++) {
  DATES.push({ id: i + 1, name: i + 1 });
  if (i <= 23) HOURS.push({ id: i, name: i + " hour" });
}

export default function UserProfile() {
  const { dispatch } = useContext(LoaderContext);
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const [response, setResponse] = useState(null);
  const [allDevices, setAllDevices] = useState([]);
  const [exportReportParams, setExportReportParams] = useState({
    fromDate: moment().startOf("day").format(),
    toDate: moment().endOf("day").format(),
  });

  useEffect(() => {
    DashboardServices.GetDashData(
      () => startLoader("get dashdata"),
      getAllDevicesSuccess,
      (err) => console.log(err),
      () => stopLoader("get dashdata")
    );
  }, []);

  const getAllDevicesSuccess = ({ data }) => {
    let devices = data.data.deviceData.map((item) => {
      let device = {
        id: item.deviceName,
        name: item.deviceName + "-" + item.deviceAlias,
      };
      return device;
    });
    setAllDevices(devices);
  };

  const initialValues = useInitialValues(startLoader, stopLoader);

  const handleSubmit = (values) => {
    setResponse(null);
    AutoReportService.Update(
      values,
      () => startLoader("update report"),
      updateReportSuccess,
      updateReportError,
      () => stopLoader("update report")
    );
  };

  const updateReportSuccess = ({ data }) => {
    data.error
      ? setResponse("Update Failed")
      : setResponse("Auto Report Config Updated");
  };

  const updateReportError = (err) => {
    setResponse("Update Failed");
    console.log(err);
  };

  const handleSubmitExportReport = async (values) => {
    const { deviceIds } = values;
    const sheetsData = [];
    const sheetNames = [];
    const sheetheaders = [];
    for await (let deviceId of deviceIds) {
      const { data = {} } = await AutoReportService.getExports(
        deviceId,
        exportReportParams.fromDate,
        exportReportParams.toDate
      );
      let { dataArray = [], header = [] } = data;
      if (header.length) {
        dataArray = dataArray.map((item) => {
          const cur = { timestamp: item.timeStamp };
          header.forEach((h, i) => {
            const keyName = `column${i + 1}`;
            cur[keyName] = item[keyName];
          });
          return cur;
        });
        header.unshift("Timestamp");
      }
      sheetsData.push(dataArray);
      sheetNames.push(deviceId);
      sheetheaders.push(header);
    }
    DownloadAsExcelMultipleSheet(
      sheetsData,
      sheetNames,
      "Export Report",
      sheetheaders
    );
  };

  return (
    <div>
      <div className="info-container">
        <div>
          <div className="info-heading">{BRIEFS?.autoReport?.heading}</div>
          <ul className="info-list">
            {Object.values(BRIEFS?.autoReport?.description).map((desc) => (
              <li>{desc}</li>
            ))}
          </ul>
        </div>
      </div>
      <GridContainer style={{ marginTop: "1vw" }}>
        <GridItem xs={12} sm={12} md={6} style={{ margin: "auto" }}>
          <Card className="margin-auto auto-report-card">
            <CardHeader color="primary">
              <h4>Export Report</h4>
            </CardHeader>
            <CardBody>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h6>Start Date</h6>
                    <div class="auto-report-field">
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        className="m-0"
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="fromDate"
                        label="Start Date"
                        onChange={(date) =>
                          setExportReportParams((prev) => ({
                            ...prev,
                            fromDate: moment(date).format(),
                          }))
                        }
                        value={exportReportParams.fromDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                        disableFuture={true}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "25px" }}
                  >
                    <h6>End Date</h6>

                    <div class="auto-report-field">
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        className="m-0"
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="toDate"
                        label="End Date"
                        value={exportReportParams.toDate}
                        minDate={moment(exportReportParams.fromDate)
                          .add(0, "d")
                          .format()}
                        maxDate={moment(exportReportParams.fromDate)
                          .add(7, "d")
                          .format()}
                        onChange={(date) =>
                          setExportReportParams((prev) => ({
                            ...prev,
                            toDate: moment(date).format(),
                          }))
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                        disableFuture={true}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </MuiPickersUtilsProvider>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} style={{ marginTop: "25px" }}>
                  <h6>Devices</h6>
                  <div class="auto-report-field">
                    <Formik
                      initialValues={{ deviceIds: [] }}
                      enableReinitialize
                      onSubmit={handleSubmitExportReport}
                    >
                      <Form>
                        <Field
                          component={MultiSelectDropDown}
                          name="deviceIds"
                          type="select"
                          values={allDevices}
                          fullWidth
                          label="Devices"
                        />
                        <CardFooter className="margin-auto">
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </CardFooter>
                      </Form>
                    </Formik>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ margin: "auto" }}>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Card className="margin-auto auto-report-card">
                  <CardHeader color="primary">
                    <h4>Auto Report Configuration</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <h6>Alert</h6>
                        <div class="auto-report-field">
                          <Field
                            component={DropDown}
                            name="autoAlert"
                            type="select"
                            values={[
                              { id: "Inactive", name: "Inactive" },
                              { id: "Active", name: "Active" },
                            ]}
                            label="Status"
                            fullWidth
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Event</h6>
                        <div class="auto-report-field">
                          <Field
                            component={DropDown}
                            name="autoEvent"
                            type="select"
                            values={[
                              { id: "Inactive", name: "Inactive" },
                              { id: "Active", name: "Active" },
                            ]}
                            label="Status"
                            fullWidth
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Configuration</h6>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className="auto-report-field">
                          <Field
                            component={DropDown}
                            name="frequency"
                            type="select"
                            values={[
                              { id: "Weekly", name: "Weekly" },
                              { id: "Monthly", name: "Monthly" },
                            ]}
                            label="Frequency"
                            fullWidth
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className="auto-report-field">
                          <Field
                            component={DropDown}
                            name="day"
                            type="select"
                            values={
                              values.frequency === "Weekly" ? DAYS : DATES
                            }
                            label="Day"
                            fullWidth
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className="auto-report-field">
                          <Field
                            component={DropDown}
                            name="hour"
                            type="select"
                            values={HOURS}
                            label="Hour"
                            fullWidth
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    {response ? (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <p
                            className={
                              response !== "Update Failed"
                                ? " success-msg"
                                : "error-msg"
                            }
                          >
                            {response}
                          </p>
                        </GridItem>
                      </GridContainer>
                    ) : null}
                  </CardBody>
                  <CardFooter className="margin-auto">
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            )}
          </Formik>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const useInitialValues = (startLoader, stopLoader) => {
  const [initialValues, setInitialValues] = useState({
    autoAlert: "",
    autoEvent: "",
    frequency: "",
    day: "",
    hour: 0,
  });

  useEffect(() => {
    AutoReportService.Read(
      () => startLoader("get report"),
      getAutoReportSuccess,
      (err) => console.log(err),
      () => stopLoader("get report")
    );
  }, []);

  const getAutoReportSuccess = (res) => {
    const { autoAlert, autoEvent, frequency, day, hour } =
      res.data.data.autoReport;
    let processedData = {
      autoAlert: autoAlert || "Inactive",
      autoEvent: autoEvent || "Inactive",
      frequency: frequency || "Monthly",
      day: day || 1,
      hour: hour || 0,
    };
    setInitialValues(processedData);
  };

  return initialValues;
};
