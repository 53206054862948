import React from 'react';
import classes from './DataRouting.module.css';

const DataRouting = () => {
    return (
        <div>
            <iframe src="https://nr.lprscsdata.com/"  className={classes.data_routing_iframe}/>
        </div>
    );
}

export default DataRouting;
