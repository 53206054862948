import React, { Fragment, useState, useContext, useEffect } from "react";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { LoaderContext } from "../../context/LoaderContext";
import { START_LOADER, STOP_LOADER, LOCAL_PATH } from "../../constants";
import { Grid, makeStyles, Typography, Chip } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import { CustomToolTip } from "../../components/CustomToolTip/CustomToolTip";
import { Details as DetailsIcon } from "@material-ui/icons";
import { AlertService } from "../../services/AlertService";
import { getFields } from "./AddAlert";
import Button from "../../components/CustomButtons/Button";

import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    button: {
        float: "right",
    },
    pagination: {
        marginLeft: "auto",
    },
});

const LIMIT = 10;

export const AlertLog = () => {
    const classes = useStyles();
    const [refresh, setRefresh] = useState(0);
    const [pagination, setPagination] = useState({ totalPages: 0, curPage: 1 });
    const [exportData, setExportData] = useState(0);
    const [criteria, setCriteria] = useState({ show: false, content: null });
    const [message, setMessage] = useState({ show: false, content: null });
    const { dispatch } = useContext(LoaderContext);
    const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });

    let _exporter;
    const exportToExcel = () => {
        _exporter.save();
    };

    useEffect(() => {
        AlertService.ExportData(getExportLogSuccess, fetchError);
    }, []);

    const alerts = useAlerts(
        startLoader,
        stopLoader,
        refresh,
        pagination.curPage,
        setPagination,
        setRefresh,
        setCriteria,
        setMessage
    );

    const getExportLogSuccess = ({ data }) => {
        console.log(data);
        const processedData = data.data.alertLogs.map((log) => {
            delete log["criteria"];
            return {
                ...log,
                createdAt: new Date(log.createdAt).toLocaleString(),
                isAck: log.isAck
                    ? new Date(log.isAck).toLocaleString()
                    : "Not Acknowledged",
            };
        });
        console.log(processedData);
        setExportData(processedData);
    };

    const fetchError = (err) => {
        console.log(err);
    };

    return (
        <Fragment>
            <FormDialog
                show={criteria.show}
                onClose={() => setCriteria({ show: false, content: null })}
                title={`Criteria`}
                content={criteria.content}
            />
            <FormDialog
                show={message.show}
                onClose={() => setMessage({ show: false, content: null })}
                title={`Message`}
                content={message.content}
            />
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Button
                        className={classes.button}
                        onClick={exportToExcel}
                        variant="contained"
                        color="primary"
                        mb={2}
                    >
                        Export Excel
                    </Button>
                    <ExcelExport
                        data={exportData}
                        fileName="AlertLog.xlsx"
                        ref={(exporter) => {
                            _exporter = exporter;
                        }}
                    >
                        <ExcelExportColumn
                            field="deviceName"
                            title="Device Name"
                            width={100}
                        />
                        <ExcelExportColumn field="contact" title="Contact" width={100} />
                        <ExcelExportColumn field="email" title="Email" width={150} />
                        <ExcelExportColumn
                            field="sensorName"
                            title="Sensor Name"
                            width={200}
                        />
                        <ExcelExportColumn
                            field="valueType"
                            title="Value Type"
                            width={100}
                        />
                        <ExcelExportColumn
                            field="alertType"
                            title="Alert Type"
                            width={100}
                        />
                        <ExcelExportColumn
                            field="triggeredAt"
                            title="Triggered At"
                            width={80}
                        />
                        <ExcelExportColumn
                            field="isAck"
                            title="Acknowledged At"
                            width={120}
                        />
                        <ExcelExportColumn
                            field="ackBy"
                            title="Acknowledged By"
                            width={120}
                        />
                        <ExcelExportColumn
                            field="mqttEnabled"
                            title="Created At"
                            width={120}
                        />
                        <ExcelExportColumn
                            field="createdAt"
                            title="MQTT Enabled"
                            width={120}
                        />
                        <ExcelExportColumn
                            field="host"
                            title="Host"
                            width={120}
                        />
                        <ExcelExportColumn
                            field="port"
                            title="Port"
                            width={120}
                        />
                        <ExcelExportColumn
                            field="topic"
                            title="Topic"
                            width={120}
                        />
                        <ExcelExportColumn
                            field="message"
                            title="Message"
                            width={200}
                        />
                    </ExcelExport>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Alerts Log</h4>
                        </CardHeader>
                        <CardBody className="overflow-x-scroll table-scroll">
                            <Table
                                tableHeaderColor="primary"
                                tableHead={[
                                    "SNo",
                                    "Device Name",
                                    "Status",
                                    "Contact",
                                    "Email",
                                    "Sensor Name",
                                    "Value Type",
                                    "Alert Type",
                                    "Triggered At",
                                    "Acknowledged At",
                                    "Acknowledged By",
                                    "Created At",
                                    "MQTT Enabled",
                                    "Host",
                                    "Port",
                                    "Topic",
                                    "Message",
                                    "Options",
                                ]}
                                tableData={alerts}
                            />
                        </CardBody>
                    </Card>
                </Grid>
                {pagination.totalPages > 1 ? (
                    <Grid item xs={12} className={classes.pagination}>
                        <Pagination
                            count={pagination.totalPages}
                            page={pagination.curPage}
                            variant="outlined"
                            color="primary"
                            onChange={(e, value) =>
                                setPagination((prev) => ({
                                    ...prev,
                                    curPage: value,
                                }))
                            }
                        />
                    </Grid>
                ) : null}
            </Grid>
        </Fragment>
    );
};

const useAlerts = (
    startLoader,
    stopLoader,
    refresh,
    curPage,
    setPagination,
    setRefresh,
    setCriteria,
    setMessage
) => {
    const [alerts, setAlerts] = useState([]);
    const history = useHistory();
    useEffect(() => {
        AlertService.ReadAlertLog(
            LIMIT,
            (curPage - 1) * LIMIT,
            () => startLoader("alertLogFetch"),
            handleAlertFetchSuccess,
            handleAlertFetchError,
            () => stopLoader("alertLogFetch")
        );
    }, [curPage, refresh]);

    const handleAlertFetchSuccess = (res) => {
        const data = res.data.data;
        const pagination = data.pagination;
        setPagination((prev) => ({
            ...prev,
            totalPages: Math.ceil(pagination.totalCount / LIMIT),
        }));
        const processedData = data.alertLogs.map((item) => [
            <p
                className="device-link"
                onClick={() => history.push(LOCAL_PATH.DEVICE, { id: item.deviceName })}
            >
                {item.deviceName}
            </p>,
            getStatus(item.isAck, item.deviceName),
            item.contact,
            item.email,
            item.sensorName,
            item.valueType,
            item.alertType,
            item.triggeredAt,
            item.isAck ? new Date(item.isAck).toLocaleString() : "Not Acknowledged",
            item.ackBy,
            new Date(item.createdAt).toLocaleString(),
            item.mqttEnabled,
            item.host || '--',
            item.port || '--',
            item.topic || '--',
            getMessage(item),
            getOptions(item),
        ]);
        setAlerts(processedData);
    };
    const handleAlertFetchError = (err) => {
        console.log(err);
    };
    const showCriteria = (item) => {
        const { aLabel, bLabel, bDisabled } = getFields(item);
        const content = (
            <>
                <Typography>
                    {aLabel} : {item.criteria.values && item.criteria.values[0]}
                </Typography>
                {bDisabled || (
                    <Typography>
                        {bLabel} :{" "}
                        {(item.criteria.values && item.criteria.values[1]) ||
                            item.criteria.timePeriod}
                    </Typography>
                )}
            </>
        );
        return setCriteria({ show: true, content });
    };

    const showMessage = (message) => {
        const content = (
          <>
            <Typography>{message}</Typography>
          </>
        );
        return setMessage({ show: true, content });
    };

    const getStatus = (status, id) => {
        const text = status ? ["Acknowledged", "#27b042"] : ["Pending", "#f53636"];
        return (
            <Chip
                label={text[0]}
                title={!status ? "Click to Acknowledge" : "Acknowledged"}
                style={{ background: text[1] }}
                onClick={!status ? () => handleAck(id) : undefined}
            />
        );
    };

    const handleAck = (id) => {
        AlertService.AckAlert(
            id,
            () => startLoader("ackAlert"),
            ackSuccess,
            ackError,
            () => stopLoader("ackAlert")
        );
    };

    const ackSuccess = (res) => {
        setRefresh((prev) => prev + 1);
    };
    const ackError = (err) => {
        console.log(err);
    };

    const getOptions = (item) => {
        return (
            <>
                <CustomToolTip
                    title="View Criteria"
                    onClick={() => showCriteria(item)}
                    Icon={DetailsIcon}
                />
            </>
        );
    };

    const getMessage = (item) => {
        return (
            <>
                {item.message ? (
                    <CustomToolTip
                        title={item.message}
                        onClick={() => showMessage(item.message)}
                        Icon={DetailsIcon}
                    />
                ) : (
                    <div>--</div>
                )}
            </>
        );
    };
    return alerts.map((x, i) => [(curPage - 1) * LIMIT + i + 1, ...x]);
};
