import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoaderContext } from "../../../context/LoaderContext";
import { START_LOADER, STOP_LOADER } from "../../../constants";
import { DeviceDashboardService } from "../../../services/DeviceDashboardService";
import Button from "../../../components/CustomButtons/Button";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Card from "../../../components/Card/Card";
import { DeviceDashboardFilters } from "./DeviceDashboardFilters";
import {
  ApexChart,
  ApexSynchronizedChart,
} from "../../../components/Chart/ApexChart";
import {
  DeviceDashboardCards,
  IntelligenceCard,
  UltraSonicsCard,
} from "./DeviceDashboardCards";
import { DeviceDashboardTables } from "./DeviceDashboardTables";

import { Grid, makeStyles } from "@material-ui/core";

import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { DropDown } from "../../../components/DropDown/DropDown";
import { DashboardServices } from "../../../services/DashBoardServices";
import classCss from "../dashboard.module.css";
import WarningLogo from "../../../assets/img/warning.svg";
import moment from "moment";
import { DownloadAsExcel } from "../../../utils/downloadAsExcel";
import { AutoReportService } from "../../../services/AutoReportService";
const clear = require("../../../assets/wIcons/clear.png");
const clight = require("../../../assets/wIcons/clight.png");
const cloud = require("../../../assets/wIcons/cloud.png");
const clRain = require("../../../assets/wIcons/clRain.png");
const pcloud = require("../../../assets/wIcons/pcloud.png");
const snowCloud = require("../../../assets/wIcons/snowCloud.png");
const storm = require("../../../assets/wIcons/strom.png");
const moRain = require("../../../assets/wIcons/moRain.png");
const snow = require("../../../assets/wIcons/snow.png");
const centigrade = require("../../../assets/wIcons/c.png");

const useStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    float: "right",
    backgroundColor: "#3F51BF0A",
    color: "black",
    border: "1px solid black",
  },
  pagination: {
    marginLeft: "auto",
  },
  Wicon: {
    height: "30px",
    width: "30px",
  },
  Cicon: {
    height: "20px",
    width: "20px",
  },
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LIMIT = 10;

const DeviceDashboard = () => {
  const history = useHistory();
  const params = useParams();
  const { dispatch } = useContext(LoaderContext);
  const [deviceId, setDeviceId] = useState(
    history?.location?.state?.id || params.deviceName
  );
  const startLoader = (payload) => dispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => dispatch({ type: STOP_LOADER, payload });
  const [initialData, setInitialData] = useState({});
  const [graphData, setGraphData] = useState({});
  const [sensorData, setSensorData] = useState([]);
  const [sensorLog, setSensorLog] = useState({});
  const [sensorLogPage, setSensorLogPage] = useState(1);
  const [sensorLogTotalPages, setSensorLogTotalPages] = useState(1);
  const [filters, setFilter] = useState({});
  const [exportData, setExportData] = useState(0);
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [allDevices, setAllDevices] = useState(null);
  const classes = useStyles();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [last5SecTime, set5SecTime] = useState(null);
  const [deviceExportDataReactor, setDeviceExportDataReactor] = useState(null);

  let _exporter;
  const exportToExcel = async () => {
    // _exporter.save();
    setDeviceExportDataReactor(Date.now());
  };

  const getDeviceExportData = async (startDate, endDate) => {
    if (deviceExportDataReactor) {
      const { data = {} } = await AutoReportService.getExports(
        deviceId,
        startDate,
        endDate
      );
      let { dataArray = [], header = [] } = data;
      if (header.length) {
        dataArray = dataArray.map((item) => {
          const cur = { timestamp: item.timeStamp };
          header.forEach((h, i) => {
            const keyName = `column${i + 1}`;
            cur[keyName] = item[keyName];
          });
          return cur;
        });
        header.unshift("Timestamp");
      }
      DownloadAsExcel(dataArray, "Export Report", header);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    DashboardServices.GetDashData(
      () => startLoader("get dashdata"),
      getAllDevicesSuccess,
      (err) => console.log(err),
      () => stopLoader("get dashdata")
    );
  }, []);

  const getAllDevicesSuccess = ({ data }) => {
    let devices = data.data.deviceData.map((item) => {
      let device = {
        id: item.deviceName,
        name: item.deviceName + "-" + item.deviceAlias,
      };
      return device;
    });
    setAllDevices(devices);
  };

  useEffect(() => {
    if (deviceId) {
      setFilter({});
      let filterParams = {
        fromDate: moment().utc().startOf("day").subtract(1, "h").format(),
        toDate: moment().utc().endOf("day").subtract(1, "h").format(),
      };
      setFilter(filterParams);
      DeviceDashboardService.ReadById(
        deviceId,
        filterParams,
        () => startLoader("getDevice"),
        DataFetchSuccess,
        FetchError,
        () => stopLoader("getDevice")
      );

      DeviceDashboardService.getDeviceInfo(
        deviceId,
        () => startLoader("getDeviceInfo"),
        ({ data }) =>
          setDeviceLocation({ lat: data.data.lat, lng: data.data.lng }),
        FetchError,
        () => stopLoader("getDeviceInfo")
      );
    }
  }, [deviceId]);
  const DataSensorFetchSuccess = ({ data }) => {
    setSensorData(data?.data?.sensorOptions);
    console.log(data);
  };
  useEffect(() => {
    DeviceDashboardService.getSensorDetailsById(
      deviceId,
      () => startLoader("sensors"),
      DataSensorFetchSuccess,
      FetchError,
      () => stopLoader("sensors")
    );
  }, []);
  useEffect(() => {
    filterSensorLog(filters);
  }, [sensorLogPage, filters]);

  const DataFetchSuccess = ({ data }) => {
    setInitialData(data.data);
    let processedGraphData = data.data.graphData;
    setGraphData(processedGraphData);

    let processedData = [];
    let i;
    if (processedGraphData && processedGraphData.current)
      for (i = 1; i < processedGraphData.timeStamp.length; i++) {
        processedData.push({
          deviceId: deviceId,
          current: processedGraphData.current[i],
          timeStamp: new Date(processedGraphData.timeStamp[i]).toLocaleString(),
        });
      }
    else
      for (i = 1; i < processedGraphData.timeStamp.length; i++) {
        processedData.push({
          deviceId: deviceId,
          minimum: processedGraphData.minimum[i],
          maximum: processedGraphData.maximum[i],
          average: processedGraphData.average[i],
          timeStamp: new Date(processedGraphData.timeStamp[i]).toLocaleString(),
        });
      }
    setExportData(processedData);

    setSensorLog(data.data.sensorLog);
    setSensorLogTotalPages(
      Math.ceil(data.data.sensorLog.pagination.totalCount / LIMIT)
    );
    data?.data?.graphData?.timeStamp.length > 0
      ? set5SecTime(
          data?.data?.graphData?.timeStamp[graphData?.timeStamp.length - 1]
        )
      : set5SecTime(moment().utc().format());
    console.log("danger");
  };

  const FetchError = (err) => {
    console.log(err);
  };

  const filterDataSuccess = ({ data }) => {
    let processedGraphData = data.data;
    setGraphData(processedGraphData);
  };

  const filterSensorLogSuccess = ({ data }) => {
    setSensorLog(data.data);
    setSensorLogTotalPages(Math.ceil(data.data.pagination.totalCount / LIMIT));
  };

  const getExportLogSuccess = ({ data }) => {
    let processedData = data.data.dataArray.map((item) => {
      return {
        ...item,
        timeStamp: new Date(item.timeStamp).toLocaleString(),
      };
    });
    setExportData(processedData);
  };

  const filterSensorLog = (filterParams) => {
    DeviceDashboardService.FilterSensorLog(
      sensorLogPage,
      deviceId,
      filterParams,
      () => startLoader("filterSensorLog"),
      filterSensorLogSuccess,
      FetchError,
      () => stopLoader("filterSensorLog")
    );
  };

  const handleFilterChange = (e, filterParams) => {
    setFilter(filterParams);
    graphData?.timeStamp
      ? set5SecTime(graphData?.timeStamp[graphData?.timeStamp.length - 1])
      : set5SecTime(moment().utc().format());
    e.preventDefault();
    DeviceDashboardService.FilterGraphData(
      deviceId,
      filterParams,
      () => startLoader("filterData"),
      filterDataSuccess,
      FetchError,
      () => stopLoader("filterData")
    );
    DeviceDashboardService.getSensorExportLog(
      deviceId,
      filterParams,
      getExportLogSuccess,
      FetchError
    );
    filterSensorLog(filterParams);
  };
  const handleRefreshGraph = (filterParams, GraphData) => {
    // setFilter(filterParams);
    DeviceDashboardService.FilterGraphData(
      deviceId,
      filterParams,
      () => {},
      ({ data }) => refreshGraphDataSuccess({ data, GraphData }),
      FetchError,
      () => {}
    );
  };

  const refreshGraphDataSuccess = ({ data, GraphData }) => {
    let processedGraphData = data.data;
    //{ timeStamp: [moment().utc().format()], current: [Math.random(1000)] }
    const previousData = JSON.parse(JSON.stringify(GraphData));
    if (processedGraphData?.timeStamp.length > 0) {
      processedGraphData.timeStamp.forEach((item) => {
        previousData.timeStamp.push(item);
      });
      processedGraphData?.current &&
        processedGraphData.current.forEach((item) => {
          previousData.current.push(item);
        });
      processedGraphData?.minimum &&
        processedGraphData.minimum.forEach((item) => {
          previousData.minimum.push(item);
        });
      processedGraphData?.maximum &&
        processedGraphData.maximum.forEach((item) => {
          previousData.maximum.push(item);
        });
      processedGraphData?.average &&
        processedGraphData.average.forEach((item) => {
          previousData.average.push(item);
        });
      processedGraphData?.rainData &&
        processedGraphData.rainData.forEach((item) => {
          previousData.rainData.push(item);
        });
      setGraphData(previousData);
    }
  };
  let deviceDashboardCards;
  if (
    initialData.sensorTypes &&
    initialData.deviceInfo.template !== "Ultrasonic"
  ) {
    deviceDashboardCards = (
      <DeviceDashboardCards
        template={initialData.deviceInfo.template}
        windowDimensions={windowDimensions}
        sensors={Object.values(initialData.sensorTypes)}
        sensorValues={initialData.latestData}
      />
    );
  } else if (
    initialData.sensorTypes &&
    initialData.deviceInfo.template === "Ultrasonic"
  ) {
    deviceDashboardCards = (
      <UltraSonicsCard
        template={initialData.deviceInfo.template}
        windowDimensions={windowDimensions}
        sensors={Object.values(initialData.sensorTypes)}
        sensorValues={initialData.latestData}
        initialData={initialData}
      />
    );
  }
  let cIcon = null;
  let cText = {};
  if (initialData.weather != undefined) {
    cIcon =
      parseInt(initialData.weather.id / 100) === 2
        ? storm
        : parseInt(initialData.weather.id / 100) === 3
        ? cloud
        : parseInt(initialData.weather.id / 100) === 5
        ? moRain
        : parseInt(initialData.weather.id / 100) === 6
        ? snow
        : parseInt(initialData.weather.id / 100) === 7
        ? pcloud
        : parseInt(initialData.weather.id) === 800
        ? clear
        : cloud;
    cText.main = initialData.weather.main;
    cText.temp = initialData.weather.temperature;
    cText.enabled = initialData.weather.status;
    cText.windSpeed = initialData.weather.windSpeed;
    cText.precipitation = initialData.weather.precipitation;
    cText.city = initialData.weather.city;
    cText.country = initialData.weather.country;
  }
  let display = cText && cText.enabled === "enabled" ? "block" : "none";

  return (
    <div>
      <div className="flex flex-space-between">
        <div>
          <GridContainer>
            <div style={{ alignSelf: "center", paddingRight: "0 !important" }}>
              <div className="bold" style={{ margin: "0 0 0 32px" }}>
                Device:
              </div>
            </div>
            <GridItem>
              <DropDown
                onChange={(e) => setDeviceId(e.target.value)}
                values={allDevices}
                value={deviceId}
                fullWidth
              />
            </GridItem>
          </GridContainer>
        </div>
        {initialData.deviceInfo &&
          initialData.deviceInfo.template === "Ultrasonic" && (
            <div>
              <GridContainer>
                <div
                  style={{ alignSelf: "center", paddingRight: "0 !important" }}
                >
                  <div className="bold" style={{ margin: "0 0 0 32px" }}>
                    IMEI:
                  </div>
                </div>
                {initialData.deviceInfo &&
                initialData.deviceInfo.metaData &&
                initialData.deviceInfo.metaData.IMEI !== "0" ? (
                  <GridItem>{initialData.deviceInfo.metaData.IMEI}</GridItem>
                ) : (
                  <GridItem>-</GridItem>
                )}
              </GridContainer>
            </div>
          )}
        {initialData.latestData && (
          <div>
            <div className="last-updated">
              Last Updated :{" "}
              {initialData.latestData.timestamp
                ? new Date(initialData.latestData.timestamp).toLocaleString()
                : "Not Reported"}
            </div>
            {initialData.latestData.battery && (
              <div className="last-updated">
                Battery Level : {initialData.latestData.battery}V
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-space-between">
        <div style={{ display: display }}>
          <GridContainer>
            <div style={{ alignSelf: "center", paddingRight: "0 !important" }}>
              <div style={{ margin: "0 0 0 32px", marginBottom: "2rem" }}>
                <img className={classes.Wicon} src={cIcon} alt="clear" />
              </div>
            </div>
            <GridItem>
              <div className="bold" style={{ margin: "0 0 0 0px", bottom: 0 }}>
                <div> {cText.main != undefined ? cText.main : ""}</div>
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div style={{ display: display }}>
          <GridContainer>
            <div style={{ alignSelf: "center", paddingRight: "0 !important" }}>
              <div className="bold" style={{ margin: "0 0 0 32px" }}>
                Temperature:
              </div>
            </div>
            <GridItem>
              <div className="bold">
                {cText.temp != undefined ? cText.temp.toFixed(1) : ""}°C
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div style={{ display: display }}>
          <GridContainer>
            <div style={{ alignSelf: "center", paddingRight: "0 !important" }}>
              <div className="bold" style={{ margin: "0 0 0 32px" }}>
                Wind Speed:
              </div>
            </div>
            <GridItem>
              <div className="bold">
                {cText.windSpeed != undefined ? cText.windSpeed : ""}m/s
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div style={{ display: display }}>
          <GridContainer>
            <div style={{ alignSelf: "center", paddingRight: "0 !important" }}>
              <div className="bold" style={{ margin: "0 0 0 32px" }}>
                Precipitation:
              </div>
            </div>
            <GridItem>
              <div className="bold">
                {cText.precipitation != undefined ? cText.precipitation : ""}%
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div style={{ display: display }}>
          <GridContainer>
            <div style={{ alignSelf: "center", paddingRight: "0 !important" }}>
              <div className="bold" style={{ margin: "0 0 0 32px" }}>
                Location:
              </div>
            </div>
            <GridItem>
              <div className="bold">
                {cText.city != undefined ? `${cText.city},` : null}{" "}
                {cText.country != undefined ? cText.country : ""}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {deviceDashboardCards}
      {initialData.sensorTypes && Object.values(graphData)?.length && (
        <Card>
          <div style={{ padding: "10px" }}>
            <div className="filter-container">
              {console.log("rerendering")}
              {sensorData.length > 0 && (
                <DeviceDashboardFilters
                  windowDimensions={windowDimensions}
                  sensorOptions={graphData?.sensorOptions}
                  handleSubmit={handleFilterChange}
                  deviceId={deviceId}
                  graphData={graphData}
                  handleRefreshGraph={handleRefreshGraph}
                  last5SecTime={last5SecTime}
                  set5SecTime={set5SecTime}
                  deviceExportDataReactor={deviceExportDataReactor}
                  getDeviceExportData={getDeviceExportData}
                />
              )}
              {initialData["status"] ? (
                <Grid item style={{ marginLeft: "4%", alignSelf: "center" }}>
                  <img
                    style={{ verticalAlign: "bottom" }}
                    src={WarningLogo}
                    alt="warning"
                  />
                </Grid>
              ) : null}
              <Grid item style={{ marginLeft: "auto" }}>
                <Button
                  className={classes.button}
                  onClick={exportToExcel}
                  variant="outlined"
                  mb={2}
                >
                  Export Excel
                </Button>
                {exportData &&
                exportData[0] &&
                (exportData[0].current || exportData[0].current === 0) ? (
                  <ExcelExport
                    data={exportData}
                    fileName="GraphData.xlsx"
                    ref={(exporter) => {
                      _exporter = exporter;
                    }}
                  >
                    <ExcelExportColumn
                      field="deviceId"
                      title="Device Id"
                      width={100}
                    />
                    <ExcelExportColumn
                      field="timeStamp"
                      title="Timestamp"
                      width={200}
                    />
                    <ExcelExportColumn
                      field="current"
                      title="Current"
                      width={100}
                    />
                  </ExcelExport>
                ) : (
                  <ExcelExport
                    data={exportData}
                    fileName="GraphData.xlsx"
                    ref={(exporter) => {
                      _exporter = exporter;
                    }}
                  >
                    <ExcelExportColumn
                      field="deviceId"
                      title="Device Id"
                      width={100}
                    />
                    <ExcelExportColumn
                      field="timeStamp"
                      title="Timestamp"
                      width={200}
                    />
                    <ExcelExportColumn
                      field="minimum"
                      title="Minimum"
                      width={100}
                    />
                    <ExcelExportColumn
                      field="maximum"
                      title="Maximum"
                      width={100}
                    />
                    <ExcelExportColumn
                      field="average"
                      title="Average"
                      width={100}
                    />
                  </ExcelExport>
                )}
              </Grid>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <IntelligenceCard
                  rainSummary={graphData?.rainSummary}
                  intelligence={graphData.intelligence}
                  template={initialData?.deviceInfo?.template}
                />
              </GridItem>
              <GridItem md={9} style={{ padding: "0px" }}>
                <div className={classCss.Heading}>
                  {graphData?.timeStamp?.length === 0
                    ? "No Data Available"
                    : null}
                </div>
                <div className={classCss.Heading}>Device Data</div>
                {initialData?.sensorTypes &&
                initialData?.deviceInfo?.template === "Ultrasonic" ? (
                  <div className={classCss.Chart}>
                    <ApexSynchronizedChart
                      chartData={{
                        timeStamp: graphData?.timeStamp,
                        minimum: graphData?.minimum,
                        maximum: graphData?.maximum,
                        average: graphData?.average,
                        current: graphData?.current,
                        rainData: graphData?.rainData
                          ? graphData?.rainData
                          : [],
                      }}
                      animations={{ enable: false }}
                    />
                  </div>
                ) : (
                  <div className={classCss.Chart}>
                    <ApexChart
                      chartData={{
                        timeStamp: graphData?.timeStamp,
                        minimum: graphData?.minimum,
                        maximum: graphData?.maximum,
                        average: graphData?.average,
                        current: graphData?.current,
                      }}
                      animations={{ enable: false }}
                      stroke={{
                        width: graphData?.timeStamp?.length > 100 ? 2 : 4,
                      }}
                    />
                  </div>
                )}

                {/* <div className={classCss.Heading}>{graphData.timeStamp.length===0?"No Data Available":null}</div>
               <div className={classCss.Heading}>Rain Data</div>
               <div className={classCss.Chart}>
               <ApexChart
                  chartData={{
                    timeStamp: graphData.timeStamp,
                    minimum: graphData.minimum,
                    maximum: graphData.maximum,
                    average: graphData.average,
                    current: graphData.current,
                  }}
                />
               </div> */}
              </GridItem>
            </GridContainer>
          </div>
          <div>
            <DeviceDashboardTables
              deviceCommands={initialData?.deviceCommands}
              deviceLocation={deviceLocation}
              sensorLog={sensorLog}
              deviceId={deviceId}
              template={initialData?.deviceInfo?.template}
              initialData={initialData}
              sensorLogTotalPages={sensorLogTotalPages}
              setSensorLogPage={setSensorLogPage}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default DeviceDashboard;
